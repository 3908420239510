import styled from "styled-components"

export const Link = styled.a`
  display: inline-block;
  color: ${({ theme }) => theme.colors.dark[100]};
  text-decoration: none;
  margin: 0;
  transition: color 0.2s ease-in-out;

  border-bottom: 2px solid ${({ theme }) => theme.colors.blue[100]};

  ::selection {
    background: ${({ theme }) => theme.colors.blue[5]};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.blue[100]};
  }
`
