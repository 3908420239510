import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import { ThemeProvider } from "styled-components"
import { Normalize } from "styled-normalize"

import { CheckList, Footer, Header } from "../../components"
import { ImageCircle } from "../../components/image-circle"
import { JuryNote } from "../../components/jury-note"
import { Quote } from "../../elements"
import ReviewFlow from "../../elements/review-flow"
import { BodyText, Display, Highlight, Lead, Title } from "../../identity"
import { Container } from "../../layout"
import { theme } from "../../theme"
import FactsFigures from "../../images/migipedia/facts-figures-api-calls.svg"
import { Link } from "../../identity/typography/link"
import Cumulus from "../../images/migipedia/cumulus.svg"

export default () => {
  const {
    snack: {
      childImageSharp: { fixed: snack },
    },
    reviewFlow: {
      childImageSharp: { fluid: reviewFlow },
    },
    bike: {
      childImageSharp: { fixed: bike },
    },
    discussing: {
      childImageSharp: { fixed: discussing },
    },
    recommender: {
      childImageSharp: { fluid: recommender },
    },
    stairs: {
      childImageSharp: { fixed: stairs },
    },
    philipp: {
      childImageSharp: { fixed: philipp },
    },
  } = useStaticQuery(graphql`
    query {
      snack: file(relativePath: { eq: "migipedia/02_snack_001.jpg" }) {
        childImageSharp {
          fixed(width: 500, height: 310) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      discussing: file(
        relativePath: { eq: "migipedia/01_diskutieren_007.jpg" }
      ) {
        childImageSharp {
          fixed(width: 500, height: 310) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      recommender: file(
        relativePath: { eq: "migipedia/phone-landingpage-top.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      reviewFlow: file(relativePath: { eq: "migipedia/review-flow.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      bike: file(relativePath: { eq: "migipedia/03_bike_016.jpg" }) {
        childImageSharp {
          fixed(width: 500, height: 310) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      couch: file(relativePath: { eq: "migipedia/05_couch_010.jpg" }) {
        childImageSharp {
          fixed(width: 579, height: 387) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      stairs: file(relativePath: { eq: "migipedia/04_kitchen_012.jpg" }) {
        childImageSharp {
          fixed(width: 500, height: 310) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      philipp: file(relativePath: { eq: "migipedia/philipp-buehler.jpg" }) {
        childImageSharp {
          fixed(width: 400, height: 300) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <ThemeProvider theme={theme}>
      <>
        <Helmet>
          <title>smartive — Eingaben Best of Swiss Web 2021</title>
        </Helmet>
        <Normalize />
        <Header
          heading="Eingabe Best of Swiss Web 2021"
          subheading="Kategorie Performance Driven Campaigns"
        />

        <Container>
          <Display>
            <Highlight>MIGIPEDIA</Highlight> – 10 Jahre User im Mittelpunkt
          </Display>
          <Lead>
            Seit 2010 stehen bei Migipedia – der Community der Migros –
            Kundinnen und Kunden im Mittelpunkt. Die Ansprüche der User an die
            Plattform haben sich in dieser Zeit verständlicherweise immer wieder
            verändert. Mit dem Migipedia-Relaunch von 2020 wird diesen
            Veränderungen einmal mehr Rechnung getragen. Mit Erfolg: Die
            Community ist heute so lebendig wie nie zuvor.
          </Lead>
          <Lead>
            Über 70 000 Migros-Produkte finden sich auf{" "}
            <Link href="https://migipedia.migros.ch" target="_blank">
              Migipedia.ch
            </Link>
            . 300 000 Produktbewertungen haben Kundinnen und Kunden in den
            letzten Jahren verfasst. Allerdings sind noch immer rund ⅔ der
            Produkte nicht bewertet. Dank unterschiedlichen Recommendern und
            einem intuitiven Nutzererlebnis beim Bewerten ist es gelungen, die
            Anzahl Bewertungen um 60% zu erhöhen und gleichzeitig den Aufwand
            für Kundinnen und Kunden zu halbieren. Durch den Einsatz neuester
            Technik wurden zudem die Plattform-Betriebskosten um 90% reduziert.
          </Lead>
        </Container>

        <ImageCircle
          images={{
            left: stairs,
            right: snack,
            center: recommender,
          }}
        />

        <Container>
          <BodyText>
            <CheckList
              items={[
                "60% mehr Bewertungen im Vergleich zum Vorjahr.",
                "halbiert: die durchschnittliche Zeit, die ein Kunde für eine Bewertung aufbringen muss.",
                "120 000 neue Bewertungen wurden 2020 von der Community verfasst.",
                "105 Mio. Mal wurden die 120 000 neuen Bewertungen im letzten Jahr von der Bewertungs-API abgerufen.",
                "90% tiefere Betriebskosten. Notwendige Technik-Ablösung eliminiert bisherige Lizenzkosten von 350 000 Franken pro Jahr.",
                "Aus 3 Datenquellen (Einkaufsdaten, Community-Daten, Analytics-Daten) mit Millionen von Datensätzen werden passende Produkte ausgewählt, welche der Benutzer gut kennt und zu denen er sicherlich eine Meinung hat.",
              ]}
            />
          </BodyText>
        </Container>

        <Quote
          author="Philipp Bühler, Senior Product Owner Migipedia & Brand"
          photo={philipp}
        >
          &laquo;Dank der intelligenten Verknüpfung von Kaufdaten mit dem
          User-Verhalten ist die Migros-Community so aktiv wie nie zuvor und
          stiftet Mehrwert für alle Kundinnen und Kunden.&raquo;
        </Quote>

        <Container>
          <Title number="01">
            ⅔ der Produkte sind nicht <Highlight>bewertet</Highlight>
          </Title>
          <BodyText>
            Trotz 300 000 Produktbewertungen sind gut ⅔ der Migros-Supermarkt-
            und der Fachmarkt-Produkte von der Community noch nicht bewertet,
            wie eine Migros-interne Auswertung von Ende 2019 zeigte.
          </BodyText>
          <BodyText>
            Die nicht bewerteten Produkte landen zwar in den Warenkörben, doch
            sind es vor allem die Migros-Klassiker, die immer wieder bewertet
            werden. So zum Beispiel der Migros Kult Ice Tea Zitrone in der
            1-Liter-Packung mit gut 650 Bewertungen. Verständlich, denn nicht
            alle Produkte des täglichen Bedarfs haben einen so hohen emotionalen
            Wert wie die Migros-Klassiker Kult Ice Tea Zitrone, Seehund
            Rahmglace Vanille oder Blévita Gruyère. Zudem dürfte es auch der
            nicht perfekten Suche im Migros-Produktekatalog geschuldet sein,
            dass die Navigation durch über 70 000 online verfügbare Produkte
            nicht ganz einfach ist. Produktbewertungen sind aus Local-SEO-Sicht
            relevant,{" "}
            <Link
              href="https://www.shopify.com/retail/119916611-how-online-reviews-impact-local-seo-and-why-they-matter-to-your-bottom-line"
              target="_blank"
            >
              wie Shopify im eigenen Blog festhält
            </Link>
            , und stiften Orientierung für Kundinnen und Kunden. Gemäss dem{" "}
            <Link
              href="https://www.searchenginejournal.com/online-review-statistics/329701/#close"
              target="_blank"
            >
              Search Online Journal
            </Link>{" "}
            vertrauen fast 80 Prozent der Kundinnen und Kunden
            Produktbewertungen. Wie schafft es die Migros, von den Klassikern
            abzulenken und Bewertungen für noch nicht bewertete Produkte zu
            generieren?
          </BodyText>
        </Container>

        <Container>
          <Title number="02">
            Kleine Recommender, <Highlight>grosse Wirkung</Highlight>
          </Title>
          <BodyText>
            Ein Produkt ernsthaft bewerten kann man nur, wenn man es kennt. Ein
            Produkt kennt man, wenn man es ausprobiert hat. Genau auf dieser
            Logik basiert einer der wichtigsten Recommender auf Migipedia, die
            seit 2020 im Einsatz sind. Wer seinen Community-Account mit seiner
            Cumulus-Karte verknüpft, bekommt auf der Startseite jeweils drei
            Produkte seines letzten Einkaufs gezeigt. Charmant werden User
            gefragt, ob sie diese Produkte bewerten möchten. Nicht nur der
            letzte Einkauf, sondern auch oft gekaufte Produkte oder Produkte,
            die von anderen aktuell bewertet werden, sowie Aktionen und
            Neuheiten werden den Community-Mitgliedern empfohlen. Je nach
            Login-Status kommen unterschiedliche Empfehlungen zum Einsatz.
          </BodyText>
          <ReviewFlow />

          <BodyText>
            Zugegeben, Migipedia ist nicht die erste Plattform, die auf
            Recommender setzt. Allerdings lösen sie das eingangs beschriebene
            Problem äusserst erfolgreich. Im Zusammenspiel mit einem neu
            entwickelten, involvierenden Nutzererlebnis beim Bewerten konnte die
            Anzahl der Bewertungen um 60% erhöht werden, wie eine Verknüpfung
            der Kaufdaten von Kundinnen und Kunden mit neu verfassten
            Bewertungen zeigt. Der Bewertungsaufwand für Community-Mitglieder –
            gemessen mittels Event-Tracking im Google Tag Manager – konnte durch
            die Nutzerführung halbiert werde, ohne dass Bewertungen an
            inhaltlicher Qualität verloren hätten. Heute wird im Schnitt alle
            2,5 Minuten ein Produkt bewertet. Am fleissigsten war die Community
            übrigens am 23. Mai 2020. Mit 1200 neuen Bewertungen innert 24
            Stunden hagelte es Produktmeinungen im Minutentakt.
          </BodyText>
        </Container>

        <Container>
          <Cumulus />
        </Container>

        <Container>
          <Title number="03">
            <Highlight>Recommender</Highlight>: Der Migros auf den Leib
            geschneidert
          </Title>
          <BodyText>
            Google, Amazon oder auch Digitec Galaxus machen es vor – Recommender
            sind heute keine Neuheit mehr. Die Herausforderung bestand
            allerdings darin, Recommender einzusetzen, die einen tatsächlichen
            Kundennutzen bieten und sich technisch perfekt in die komplexe
            digitale Systemlandschaft der Migros einfügen. Hierfür werden bei
            Migipedia diverse Datenquellen beigezogen. Aufgrund von Kundendaten
            wie Kaufverhalten (Cumulus-Auswertungen), dem Verhalten auf der
            Website (Google Analytics Tracking) und den Beiträgen in der
            Community (MySQL-Datenbank) werden Produkte angezeigt, welche der
            Benutzer kennt und zu denen er sicherlich eine Meinung hat. Im
            Hintergrund wirken performante Mechanismen, damit die angezeigten
            Daten aktuell sind. So bringen wir User dazu, vertraute Produkte zu
            bewerten. Auch Produkte, welche der Benutzer entdecken soll, werden
            individuell präsentiert.
          </BodyText>
        </Container>

        <ImageCircle
          images={{
            left: discussing,
            right: bike,
            center: reviewFlow,
          }}
        />

        <Container>
          <Title number="04">
            <Highlight>Mehr Produktbewertungen</Highlight>, tiefere
            Betriebskosten
          </Title>
          <BodyText>
            Bis vor kurzem setzte die Migros allerdings auf eine Software als
            Service-Community-Lösung, die aufwendig erweitert werden musste,
            damit das Zusammenspiel zwischen den unterschiedlichen
            Migros-Systemen und der Community-Software möglich war. Die
            Lizenzkosten für diese eingekaufte SaaS-Lösung betrugen 350 000
            Franken jährlich. An Produktempfehlungen, wie sie heute auf
            Migipedia im Einsatz sind, war mit dieser Technik nicht zu denken.
            Insgesamt 185 000 Franken investierte die Migros in die neue
            Technik, basierend auf Next.js mit React, GraphQL als Datenquelle
            und diversen Anbindungen weiterer Migros-spezifischer
            Schnittstellen. Seit Anfang 2020 ist Migipedia lizenzfrei. Die
            Migros kann die jährlichen Lizenzkosten von 350 000 Franken
            einsparen und die Betriebskosten so um 90% reduzieren.
          </BodyText>
        </Container>

        <Container>
          <FactsFigures />
        </Container>

        <Container>
          <Title number="05">Input vs. Output</Title>
          <BodyText>
            Knapp 120 000 neue Bewertungen verfasste die Migros-Community 2020.
            Ihre Motivation ist rein intrinsisch, denn auf eine Belohnung oder
            Gamification wird auf ausdrücklichen Wunsch der Community selbst
            nach wie vor verzichtet. Wie immer in Communitys arbeiten auch bei
            Migipedia wenige User für viele. Die 120 000 neuen
            Produktbewertungen wurden im letzten Jahr 105 Mio. Mal von der
            Bewertungs-API abgerufen. So schaffen wenige User immensen Mehrwert
            für viel Kundinnen und Kunden. Auch dank der Recommender, die seit
            letztem Jahr im Einsatz sind.
          </BodyText>
        </Container>

        <JuryNote />

        <Footer />
      </>
    </ThemeProvider>
  )
}
